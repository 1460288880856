import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';
import {
  CssBaseline,
  Divider,
  List,
  ListSubheader,
  ListItem,
  Grid,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Button,
} from '@mui/material';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import {
  MoveToInbox as MoveToInboxIcon,
  Logout as LogoutIcon,
  Login as LoginIcon,
  Password as PasswordIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import { menuIcons, menuItemButtons } from './constants';
import { ResetPasswordFormValues, iconKeyString } from './interfaces';
import {
  RootContainer,
  StyledDrawerList,
  StyledContent,
  ContetnWithoutPadding,
  StyledChildrenContent,
  StyledAppBar,
  LogoImage,
  StyledListItem,
  StyledDrawerPaper,
} from './StyledComponents';
import { Dialog } from '../Dialog';
import { LogoutDialog, LoginDialog } from '../Dialogs';
import { LanguageMenu } from '../LanguageMenu';
import {
  OwnersContextProvider,
  TypesContextProvider,
  EmployeesContextProvider,
  UserContext,
} from '../../contexts';
import { ChangePassword } from '../../commonTypes';

import evhardWhite from '../../evhardWhite.svg';

const AppLayoutComponent: FC<PropsWithChildren<RouteComponentProps>> = (
  props
) => {
  const { children, history } = props;

  const { userData, setUser } = useContext(UserContext);

  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [pass, setPass] = useState<{ data: ChangePassword[] }>({
    data: [],
  });
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const isLogged = userData;

  const handleResetPassword = () => {
    setResetDialogOpen(!resetDialogOpen);
  };
  const { location } = history;
  const { pathname } = location;
  function setRedirect(path: string) {
    setOpen(false);
    history.push(path);
  }

  useEffect(() => {
    if (isLogged && pathname === '/') {
      history.push('/dashboard');
    }
  }, [pathname, isLogged, history]);

  const onSubmit = async (
    values: {
      oldPassword: string;
      newPassword: string;
      newPasswordConfirm: string;
    },
    form: FormApi<ResetPasswordFormValues, Partial<ResetPasswordFormValues>>
  ) => {
    if (!userData?.user._id) return;
    if (values?.newPassword !== values?.newPasswordConfirm) {
      enqueueSnackbar(<Trans i18nKey="notifications.passwordsNotMatch" />, {
        variant: 'error',
      });
      form.change('newPassword', '');
      form.change('newPasswordConfirm', '');
      return;
    }
    if (values?.newPassword === values?.oldPassword) {
      enqueueSnackbar(
        <Trans i18nKey="notifications.currentPasswordMatchNewPassword" />,
        {
          variant: 'error',
        }
      );
      form.change('newPassword', '');
      form.change('newPasswordConfirm', '');
      return;
    }

    let email = userData?.user.email;
    let password = values.oldPassword;
    let passArr = { email, password };

    try {
      const res = await fetch('/api/users/user/login', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(passArr),
      }).then((data) => data.json());

      if (res.user && res.user.token) {
        setUser!(res);
      } else {
        enqueueSnackbar(<Trans i18nKey="notifications.badCredentials" />, {
          variant: 'error',
        });
        form.change('newPassword', '');
        form.change('newPasswordConfirm', '');
        form.change('oldPassword', '');
        return;
      }
    } catch (exception) {
      console.log(exception);
      enqueueSnackbar(<Trans i18nKey="notifications.errorWhenSaving" />, {
        variant: 'error',
      });
    }

    try {
      await fetch(`/api/users/user/changePassword`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'POST',
        body: JSON.stringify({ password: values?.newPassword }),
      })
        .then((res) => res.json())
        .then((res) => {
          const _pass = [...pass.data];
          const index = _pass.findIndex(({ _id }) => res._id === _id);
          _pass.splice(index, 1, res);

          setPass({ data: _pass });
          enqueueSnackbar(<Trans i18nKey="notifications.passwordChanged" />, {
            variant: 'success',
          });
          setResetDialogOpen(!resetDialogOpen);
        });
    } catch (exception) {
      console.log(exception);
      enqueueSnackbar(<Trans i18nKey="notifications.errorWhenSaving" />, {
        variant: 'error',
      });
    }
  };

  const drawer = (
    <StyledDrawerList>
      {!userData ? (
        <>
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Login
              </ListSubheader>
            }
          >
            <ListItem onClick={() => setRedirect('/login')}>
              <ListItemIcon>
                <MoveToInboxIcon />
              </ListItemIcon>
              <ListItemText primary="Přihlášení" />
            </ListItem>
            <ListItem onClick={() => setRedirect('/register')}>
              <ListItemIcon>
                <MoveToInboxIcon />
              </ListItemIcon>
              <ListItemText primary="Registrace" />
            </ListItem>
          </List>
          <Divider />
        </>
      ) : (
        <>
          <List>
            {menuItemButtons.map(({ label, path, icon }) => {
              const IconComponent = menuIcons[icon as iconKeyString];
              return (
                <StyledListItem
                  selected={pathname === path}
                  onClick={() => setRedirect(path)}
                >
                  <ListItemIcon>
                    <IconComponent
                      sx={{ color: pathname === path ? '#FFF' : '#555555' }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={label} />
                </StyledListItem>
              );
            })}
          </List>

          <List>
            <StyledListItem onClick={handleResetPassword}>
              <ListItemIcon>
                <PasswordIcon sx={{ color: '#555555' }} />
              </ListItemIcon>
              <ListItemText
                primary={<Trans i18nKey="menu.menuChangePassword" />}
              />
            </StyledListItem>

            <StyledListItem
              onClick={() => setLogoutDialog(!logoutDialog)}
              data-testid="logout-btn"
            >
              <ListItemIcon>
                <LogoutIcon sx={{ color: '#555555' }} />
              </ListItemIcon>
              <ListItemText primary={<Trans i18nKey="menu.menuLogout" />} />
            </StyledListItem>
          </List>
        </>
      )}
    </StyledDrawerList>
  );

  let mainContent = (
    <RootContainer>
      <CssBaseline />
      <StyledAppBar position="fixed">
        <Toolbar sx={{ display: 'flex' }}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            sx={{ order: { xs: '0', md: '1' } }}
          >
            <a href={isLogged ? '/dashboard' : '/'}>
              <LogoImage src={evhardWhite} alt="" className="logo" />
            </a>
            <div style={{ flex: 1 }} />
            {isLogged ? (
              <LogoutIcon
                sx={{ color: '#FFF', cursor: 'pointer', marginRight: '16px' }}
                onClick={() => setLogoutDialog(!logoutDialog)}
              />
            ) : null}

            {!isLogged && pathname !== '/' ? (
              <LoginIcon
                sx={{ color: '#FFF', cursor: 'pointer', marginRight: '16px' }}
                onClick={() => setIsLoginOpen(!isLoginOpen)}
              />
            ) : null}

            {isLoginOpen ? (
              <LoginDialog
                onClose={() => {
                  setIsLoginOpen(!isLoginOpen);
                }}
              />
            ) : null}

            <LanguageMenu />
          </Grid>
          {isLogged && (
            <Button
              data-testid="open-close-menu-btn"
              onClick={() => setOpen((prevOpen) => !prevOpen)}
              sx={{ color: 'white' }}
            >
              {isOpen ? <CloseIcon /> : <MenuIcon />}
            </Button>
          )}
          {logoutDialog ? (
            <LogoutDialog onClose={() => setLogoutDialog(false)}></LogoutDialog>
          ) : null}
          {resetDialogOpen ? (
            <Dialog
              title={<Trans i18nKey="changePasswordForm.description" />}
              onClose={handleResetPassword}
            >
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div>
                        <TextField
                          required
                          margin="dense"
                          name="oldPassword"
                          type="password"
                          style={{ margin: '10px 0' }}
                          placeholder={t('changePasswordForm.oldPsw') as string}
                        />
                        <TextField
                          required
                          margin="dense"
                          name="newPassword"
                          type="password"
                          style={{ margin: '10px 0' }}
                          placeholder={t('changePasswordForm.newPsw') as string}
                        />
                        <TextField
                          required
                          margin="dense"
                          name="newPasswordConfirm"
                          type="password"
                          style={{ margin: '10px 0' }}
                          placeholder={
                            t('changePasswordForm.confirmNewPsw') as string
                          }
                        />
                      </div>
                      <Grid container spacing={2}>
                        <Grid item style={{ marginTop: 10 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            sx={{ boxShadow: 'none' }}
                          >
                            <Trans i18nKey="labels.send" />
                          </Button>
                        </Grid>
                        <Grid item style={{ marginTop: 10 }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            sx={{ boxShadow: 'none', color: '#496AEC' }}
                            onClick={handleResetPassword}
                          >
                            <Trans i18nKey="labels.cancel" />
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              />
            </Dialog>
          ) : null}
        </Toolbar>
      </StyledAppBar>

      {isLogged ? (
        <StyledDrawerPaper open={isOpen} onClose={() => setOpen(false)}>
          {drawer}
        </StyledDrawerPaper>
      ) : null}

      <StyledContent sx={{ paddingBottom: { xs: '50px', md: 'unset' } }}>
        {isLogged ? (
          <StyledChildrenContent>{children}</StyledChildrenContent>
        ) : (
          <ContetnWithoutPadding>{children}</ContetnWithoutPadding>
        )}
      </StyledContent>
    </RootContainer>
  );

  if (userData) {
    mainContent = (
      <EmployeesContextProvider>
        <OwnersContextProvider>
          <TypesContextProvider>{mainContent}</TypesContextProvider>
        </OwnersContextProvider>
      </EmployeesContextProvider>
    );
  }

  return mainContent;
};

export const AppLayout = withRouter(AppLayoutComponent);
