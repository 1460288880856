import React, { useState, useContext, FC } from 'react';
import { Form } from 'react-final-form';
import { Grid, Button } from '@mui/material';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';

import { UserContext } from '../contexts';
import { Trans } from '../i18n';
import { FormApi } from 'final-form';
import { useHistory } from 'react-router';

interface FormData {
  newPassword?: string;
  newPasswordConfirm?: string;
}

interface NewPasswordFormProps {
  id?: string;
}

const initialValues: FormData = {
  newPassword: '',
  newPasswordConfirm: '',
};

export const NewPasswordForm: FC<NewPasswordFormProps> = (props) => {
  const { id } = props;
  const { setUser, setLanguage } = useContext(UserContext);
  const [formKey] = useState(Date.now());
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (
    values: FormData,
    form: FormApi<FormData, FormData>
  ) => {
    if (values.newPassword !== values.newPasswordConfirm) {
      form.change('newPassword', '');
      form.change('newPasswordConfirm', '');
      enqueueSnackbar(<Trans i18nKey="notifications.notMatchingPasswords" />, {
        variant: 'error',
      });
      return;
    }

    if (!id) {
      return;
    }

    try {
      const res = await fetch(`/api/users/user/change-forgotten-password`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          password: values?.newPassword,
          id: id,
        }),
      });
      if (res.status === 200) {
        enqueueSnackbar(
          <Trans i18nKey="notifications.passwordChangedSuccess" />,
          { variant: 'success' }
        );
        const userData = await res.json();
        setUser!(userData);
        history.push('/dashboard');
      }

      const confirmUser = async () => {
        const res = await fetch('/api/users/user/confirm', {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ id: id }),
        }).then((res) => res.json());

        if (res.user) {
          enqueueSnackbar(
            <Trans i18nKey="notifications.userActivationSuccess" />,
            {
              variant: 'success',
            }
          );

          setUser!(res);

          if (res.userInfo.preferredLanguage) {
            setLanguage?.(res.userInfo.preferredLanguage);
          }

          history.push('/dashboard');
        }
      };
      confirmUser();
    } catch (exception) {
      console.log(exception);
      enqueueSnackbar(<Trans i18nKey="notifications.errorWhenSaving" />, {
        variant: 'error',
      });
    }
  };

  return (
    <Form
      key={formKey}
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, pristine, form }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid alignItems="flex-start" spacing={2}>
            <Grid item marginTop={1}>
              <TextField
                label={<Trans i18nKey="labels.newPassword" />}
                type="password"
                name="newPassword"
                margin="none"
                required
              />
            </Grid>
            <Grid item marginTop={2}>
              <TextField
                label={<Trans i18nKey="labels.newPasswordRepeat" />}
                type="password"
                name="newPasswordConfirm"
                margin="none"
                required
              />
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
              >
                <Trans i18nKey="labels.send" />
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};
