import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Buffer } from 'buffer';
import { useSnackbar } from 'notistack';
import { Button, Grid } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { AddFileDialog } from './AddFileDialog';
import { UserContext } from '../contexts';
import { Documents } from '../commonTypes';
import { AlertDialog } from '../components';

interface FileFormProps {
  onCancel: () => void;
  hwId?: string;
}

export const FileForm: FC<FileFormProps> = (props) => {
  const { onCancel, hwId } = props;
  const { userData } = useContext(UserContext);
  const [documents, setDocuments] = useState<Documents[]>();
  const [isOpenAddFileDialog, setOpenAddFileDialog] = useState(false);
  const [deleteFileID, setDeleteFileID] = useState('');
  const [isOpenRemoveDialog, setOpenRemoveDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getFiles = useCallback(async () => {
    try {
      const res = await fetch(`/api/documents/get-documents/${hwId}`, {
        headers: {
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'GET',
      }).then((data) => data.json());
      if (res) {
        setDocuments(res);
      }
    } catch (exception) {
      console.log(exception);
    }
  }, [hwId, userData]);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  const deleteFile = async (idFile: string) => {
    const res = await fetch(`/api/documents/delete/${idFile}`, {
      headers: {
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'DELETE',
    }).then((data) => data.json());
    if (res.error) {
      enqueueSnackbar(res.error, {
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar(<Trans i18nKey="notifications.deleted" />, {
      variant: 'success',
    });

    getFiles();
  };

  const download = async (fileID: string) => {
    await fetch(`/api/documents/file-download/${fileID}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData!.user.token}`,
      },
      method: 'GET',
    }).then((data) => {
      data.json().then((res) => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.download = res.file.description.endsWith(res.file.fileFormat)
          ? res.file.description
          : res.file.description + '.' + res.file.fileFormat;
        link.href = window.URL.createObjectURL(
          new Blob([Buffer.from(res.data, 'base64')])
        );

        link.click();
        window.URL.revokeObjectURL(link.href);
        link.remove();
      });
    });
  };

  return (
    <Grid container alignItems="flex-start" data-testid="documents-modal-form">
      <Grid container item xs={12}>
        {documents ? (
          documents.map((document) => (
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={10}>
                {document.description}
              </Grid>
              <Grid item xs={1}>
                <Button
                  color="error"
                  sx={{ boxShadow: 'none', minWidth: '50px' }}
                  onClick={() => {
                    setDeleteFileID(document?._id);
                    setOpenRemoveDialog(!isOpenRemoveDialog);
                  }}
                  data-testid="delete-document-btn"
                  data-id={document._id}
                >
                  <DeleteOutlineIcon />
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  color="primary"
                  sx={{ boxShadow: 'none', minWidth: '50px' }}
                  onClick={() => download(document._id)}
                >
                  <FileDownloadOutlinedIcon />
                </Button>
              </Grid>
              <div
                style={{
                  height: '1px',
                  background: 'rgba(0, 0, 0, 0.12)',
                  width: 'calc(100% + 32px)',
                  margin: '0 -16px',
                  marginTop: '8px',
                  marginBottom: '8px',
                }}
              />
            </Grid>
          ))
        ) : (
          <Grid>
            <Trans i18nKey="fileForm.addDocuments" />
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        position={{ xs: 'sticky', lg: 'relative' }}
        bottom={{ xs: '-16px' }}
        bgcolor={{ xs: 'white' }}
        sx={{
          marginTop: '16px',
        }}
      >
        <Grid
          container
          justifyContent="center"
          sx={{
            maxWidth: 'calc(100% + 32px)',
            width: 'calc(100% + 32px)',
            marginInline: '-16px',
            paddingInline: '16px',
            background: '#fff',
          }}
        >
          <Grid item xs={12}>
            <Button
              type="button"
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => setOpenAddFileDialog(!isOpenAddFileDialog)}
              data-testid="new-doc-btn"
            >
              <Trans i18nKey="fileForm.newDocument" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="button"
              fullWidth
              onClick={onCancel}
              data-testid="close-add-file-modal-btn"
            >
              <Trans i18nKey="fileForm.close" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {isOpenAddFileDialog ? (
        <AddFileDialog
          hwId={hwId}
          refresh={() => {
            getFiles();
          }}
          onClose={() => {
            setOpenAddFileDialog(!isOpenAddFileDialog);
          }}
        />
      ) : null}
      {isOpenRemoveDialog ? (
        <AlertDialog
          onClose={() => setOpenRemoveDialog(!isOpenRemoveDialog)}
          onDelete={() => {
            deleteFile(deleteFileID);
          }}
        />
      ) : null}
    </Grid>
  );
};
