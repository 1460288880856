import React, { FC, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import MaterialTable, { Column } from '@material-table/core';
import BuildIcon from '@mui/icons-material/Build';

import { TypesContext, IssueContext, UserContext } from '../contexts';
import { useMaterialTableLocalization } from '../hooks';
import { useGetTableRows, createTableIcons, tableEnum } from '../utils';
import { HardwareType, Issue } from '../commonTypes';
import {
  ButtonWithoutShadow,
  CustomTablePagination,
} from '../customStyledComponents';
import { IssueDialog } from '../components';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface TableState {
  columns: Array<Column<Issue>>;
  data: Issue[];
}

export const IssueTable: FC<{
  onCallbackDetail: (hardwareId: string, hardwareDetail: boolean) => void;
}> = ({ onCallbackDetail }) => {
  const { issueData } = useContext(IssueContext);
  const { types } = useContext(TypesContext);
  const { changeTableRows } = useContext(UserContext);
  const [openIssueDialog, setOpenIssueDialog] = useState(false);
  const [issueId, setIssueId] = useState('');
  const localization = useMaterialTableLocalization();
  const { t } = useTranslation();
  const issueTableIcons = createTableIcons('issue');

  const findHwTypeById = (typeId?: HardwareType | string) => {
    return types?.find(
      (type) => type._id === (typeof typeId === 'string' ? typeId : typeId?._id)
    )?.name;
  };

  const dataTable: TableState = {
    columns: [
      {
        title: <Trans i18nKey="tables.issueTable.description" />,
        field: 'hardware.description',
        render: (row) => {
          return <div>{row?.hardware?.description || '--'}</div>;
        },
      },
      {
        title: <Trans i18nKey="tables.issueTable.placement" />,
        field: 'hardwarePlacement.name' || 'placement.name',
        render: (row) => {
          return (
            <div>{row?.placement?.name || row?.hardwarePlacement?.name}</div>
          );
        },
      },
      {
        title: <Trans i18nKey="tables.issueTable.hwType" />,
        field: 'hwTypeName',
        render: (row) => {
          const hwTypeName = findHwTypeById(row?.hardware?.type) ?? '--';
          return <div>{hwTypeName}</div>;
        },
      },
      {
        title: <Trans i18nKey="tables.issueTable.issueType" />,
        field: 'issueType',

        render: (row) => {
          return (
            <div>
              {row?.issueType === 'broken' ? (
                <Trans i18nKey="tables.issueTable.broken" />
              ) : row?.issueType === 'missing' ? (
                <Trans i18nKey="tables.issueTable.missing" />
              ) : (
                '--'
              )}
            </div>
          );
        },
        customFilterAndSearch: (term, rowData) => {
          const normalizedTerm = term.toLowerCase();
          const brokenTranslationValue = t(
            'tables.issueTable.broken'
          ).toLowerCase();
          const missingTranslationValue = t(
            'tables.issueTable.missing'
          ).toLowerCase();
          return (
            (brokenTranslationValue.includes(normalizedTerm) &&
              rowData.issueType === 'broken') ||
            (missingTranslationValue.includes(normalizedTerm) &&
              rowData.issueType === 'missing')
          );
        },
      },
      {
        title: <Trans i18nKey="tables.issueTable.issueDescription" />,
        field: 'issueDescription',
        render: (row) => {
          return <div>{row?.issueDescription || '--'}</div>;
        },
      },

      {
        title: <Trans i18nKey="tables.issueTable.status" />,
        field: 'resolution.status',
        render: (row) => {
          return (
            <div>
              {row?.resolution?.status === 'inprogress' ? (
                <Trans i18nKey="tables.issueTable.inprogress" />
              ) : row?.resolution?.status === 'done' ? (
                <Trans i18nKey="tables.issueTable.done" />
              ) : (
                <Trans i18nKey="tables.issueTable.new" />
              )}
            </div>
          );
        },
        customFilterAndSearch: (term, rowData) => {
          const normalizedTerm = term.toLowerCase();
          const newTranslationValue = t('tables.issueTable.new').toLowerCase();
          const inprogressTranslationValue = t(
            'tables.issueTable.inprogress'
          ).toLowerCase();
          return (
            (newTranslationValue.includes(normalizedTerm) &&
              rowData.resolution?.status === undefined) ||
            (inprogressTranslationValue.includes(normalizedTerm) &&
              rowData.resolution?.status === 'inprogress')
          );
        },
      },
    ],
    data: issueData.map((issue) => ({
      ...issue,
      hwTypeName: findHwTypeById(issue?.hardware?.type),
    })),
  };

  return (
    <div>
      <MaterialTable
        title={<Trans i18nKey="tables.issueTable.tableTitle" />}
        localization={localization}
        icons={issueTableIcons}
        columns={dataTable.columns}
        data={dataTable.data}
        components={{
          Pagination: CustomTablePagination,
        }}
        actions={[
          (rowData) => ({
            icon: () => {
              return (
                <Button
                  onClick={() => {
                    setOpenIssueDialog(!openIssueDialog);
                    setIssueId(rowData._id);
                  }}
                  color="primary"
                  variant="contained"
                  sx={{ boxShadow: 'none', minWidth: '50px' }}
                >
                  <BuildIcon />
                </Button>
              );
            },
            onClick: (_event, _rowData) => {},
          }),

          (rowData) => ({
            icon: () => (
              <ButtonWithoutShadow
                variant="contained"
                color="secondary"
                sx={{ color: '#496AEC' }}
              >
                <InfoOutlinedIcon />
              </ButtonWithoutShadow>
            ),
            tooltip: t('tables.hwList.detail') as string,
            onClick: (_event, row) => {
              if (!Array.isArray(row)) {
                onCallbackDetail(row.hardware._id, true);
              }
            },
            hidden: !rowData.hardware,
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          pageSize: useGetTableRows(tableEnum.issueTable),
        }}
        onRowsPerPageChange={(value) => {
          changeTableRows?.(tableEnum.issueTable, value);
        }}
      />

      {openIssueDialog ? (
        <IssueDialog
          issueId={issueId}
          onClose={() => setOpenIssueDialog(!openIssueDialog)}
        />
      ) : null}
    </div>
  );
};
