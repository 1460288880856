import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  PropsWithChildren,
  FC,
} from 'react';
import { Hardware } from '../commonTypes';
import { UserContext } from './UserContext';

interface FilterParams {
  companyId: string;
  param: boolean | null;
}
interface IHardwareContext {
  globalHardware: Hardware[];
  nextMonthRevisionHardware: Hardware[];
  setHardwareUpdate(hardwareUpdate: boolean): void;
  hardwareUpdate: boolean;
  companyIds: string[];
  setCompanyIds: (company: string[]) => void;
  filterParams: FilterParams;
  setFilterParams: (params: FilterParams) => void;
}

export const HardwareContext = createContext<IHardwareContext>({
  setHardwareUpdate: () => {},
  hardwareUpdate: false,
  globalHardware: [],
  nextMonthRevisionHardware: [],
  companyIds: [],
  setCompanyIds: () => {},
  filterParams: { companyId: '', param: null },
  setFilterParams: () => {},
});

export const HardwareContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [globalHardware, setGlobalHardware] = useState([]);
  const [nextMonthRevisionHardware, setNextMonthRevisionHardware] = useState(
    []
  );
  const [companyIds, setCompanyIds] = useState<string[]>([]);

  const [filterParams, setFilterParams] = useState<FilterParams>({
    companyId: '',
    param: null,
  });

  const { userData } = useContext(UserContext);
  const [hardwareUpdate, setHardwareUpdate] = useState(false);

  useEffect(() => {
    const companyIdParam = `companyIds=${companyIds.join(',')}`;
    const filterParamsData = `filterParams=${encodeURIComponent(
      JSON.stringify(filterParams)
    )}`;

    const url = `/api/hardware?populate=type,rentalHistory.employee,revisionType,placement&${companyIdParam}&${filterParamsData}`;

    if (userData || hardwareUpdate) {
      const getHardware = async () => {
        const response = await fetch(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData!.user.token}`,
          },
          method: 'GET',
        }).then((data) => data.json());
        setGlobalHardware(response.hardware);
        setNextMonthRevisionHardware(response.nextMonthRevisionHardware);
      };
      getHardware();
      setHardwareUpdate(false);
    }
  }, [userData, hardwareUpdate, companyIds, filterParams]);

  return (
    <HardwareContext.Provider
      value={{
        nextMonthRevisionHardware,
        globalHardware,
        setHardwareUpdate,
        hardwareUpdate,
        companyIds,
        setCompanyIds,
        filterParams,
        setFilterParams,
      }}
    >
      {children}
    </HardwareContext.Provider>
  );
};
