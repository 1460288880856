import React, { FC, ReactText, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { format } from 'date-fns';

import MaterialTable, { Column } from '@material-table/core';

import { CompanyContext, HardwareContext, UserContext } from '../contexts';
import { useMaterialTableLocalization } from '../hooks';
import {
  useGetTableRows,
  createTableIcons,
  premiumLicenseValidityCheck,
  tableEnum,
} from '../utils';
import { Company } from '../commonTypes';
import {
  CustomTablePagination,
  Link,
  StyledDiv,
} from '../customStyledComponents';
import { PaymentDialog } from '../components';

interface TableState {
  columns: Array<Column<Company>>;
  data: Company[];
}

export const CompanyPreviewTable: FC = () => {
  const { globalUserCompany, refreshCompany } = useContext(CompanyContext);
  const { globalHardware, setFilterParams } = useContext(HardwareContext);
  const { changeTableRows } = useContext(UserContext);
  const [showPaymentDialog, setShowPaymentDialog] = useState<ReactText>();
  const [companyData, setCompanyData] = useState<Company>();

  const history = useHistory();
  const localization = useMaterialTableLocalization();
  const companyPreviewTableIcons = createTableIcons('company-preview');

  const paymentDialogSubmit = () => {
    refreshCompany?.();
    setShowPaymentDialog(undefined);
  };

  const findCompanyData = (row: Company) => {
    const foundCompany = globalUserCompany?.find(
      (comp) => comp._id === row._id
    );

    if (foundCompany) {
      setCompanyData(foundCompany);
    }
  };

  const handleRedirect = (companyId: string, param: boolean | null) => {
    const filterParamsData = `?companyId=${companyId}&param=${param}`;
    history.push(`/hardware-list/${filterParamsData}`);
    setFilterParams({ companyId, param });
  };

  const getHardwareByCompany = (companyId?: string) => {
    return globalHardware?.filter((gh) => gh.companyId === companyId);
  };

  const dataTable: TableState = {
    columns: [
      {
        title: <Trans i18nKey="tables.companyPreviewTable.companyName" />,
        field: 'companyName',
        render: (row) => {
          return <div>{row?.companyName || '--'}</div>;
        },
      },
      {
        title: <Trans i18nKey="tables.companyPreviewTable.countEquipment" />,
        field: 'countEquipment',
        render: (row) => {
          const count = getHardwareByCompany(row._id)?.length;
          return (
            <Link onClick={() => handleRedirect(row._id, null)}>{count}</Link>
          );
        },
      },
      {
        title: (
          <Trans i18nKey="tables.companyPreviewTable.countBorrowedEquipment" />
        ),
        field: 'countBorrowedEquipment',
        render: (row) => {
          const count = getHardwareByCompany(row._id).filter(
            (hw) => hw.borrowedBy !== null
          )?.length;
          return (
            <Link onClick={() => handleRedirect(row._id, true)}>{count}</Link>
          );
        },
      },
      {
        title: (
          <Trans i18nKey="tables.companyPreviewTable.countNotConfirmedEquipment" />
        ),
        field: 'countNotConfirmedEquipment',
        render: (row) => {
          const count = getHardwareByCompany(row._id).filter(
            (hw) => hw.borrowedBy !== null && hw.borrowingConfirmed === false
          )?.length;
          return (
            <Link onClick={() => handleRedirect(row._id, false)}>{count}</Link>
          );
        },
      },
      {
        title: <Trans i18nKey="tables.companyPreviewTable.validityOfLicence" />,
        field: 'licenceValidTo',
        render: (row) => {
          const date = row?.licenceValidTo
            ? format(new Date(row?.licenceValidTo), 'dd.MM.yyyy')
            : undefined;
          const licenseStatus = premiumLicenseValidityCheck(
            row?.licenceValidTo
          );
          return (
            <StyledDiv
              style={{
                width: '100px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {date || '--'}
              <div
                onClick={() => {
                  if (
                    licenseStatus.invalidLicense ||
                    licenseStatus.upcomingInvalidLicense
                  ) {
                    findCompanyData(row);
                    setShowPaymentDialog(row._id);
                  }
                }}
              >
                {licenseStatus.dot}
              </div>
            </StyledDiv>
          );
        },
      },
    ],
    data: [],
  };

  return (
    <div>
      <MaterialTable
        title={<Trans i18nKey="tables.companyPreviewTable.description" />}
        localization={localization}
        icons={companyPreviewTableIcons}
        columns={dataTable.columns}
        data={globalUserCompany ?? []}
        components={{
          Pagination: CustomTablePagination,
        }}
        options={{
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          pageSize: useGetTableRows(tableEnum.companyPreviewTable),
        }}
        onRowsPerPageChange={(value) => {
          changeTableRows?.(tableEnum.companyPreviewTable, value);
        }}
      />
      {showPaymentDialog ? (
        <PaymentDialog
          companyData={companyData!}
          onSubmit={() => paymentDialogSubmit()}
          onClose={() => setShowPaymentDialog(undefined)}
        />
      ) : null}
    </div>
  );
};
