import React, { FC, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FormApi } from 'final-form';
import { Form, Field } from 'react-final-form';
import { Grid, Button, Paper, FormControlLabel, Checkbox } from '@mui/material';
import { TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';

import { OwnerSelectField } from './OwnerSelectField';
import { TypeEquipmentSelect } from './TypeEquipmentSelect';
import { UserContext, OwnerContext } from '../contexts';
import { Validations } from '../utils';
import { HardwareType, Company, FormFields } from '../commonTypes';
import { Dialog } from '../components';

interface AddHardwareTypeDialogProps {
  onClose?: () => void;
  onSubmit: (hw: HardwareType) => void;
  selectedCompany?: string;
}

interface FormData {
  name?: string;
  isDateOfPurchaseRequired?: boolean;
  isInventoryNumberRequired?: boolean;
  isSerialNumberRequired?: boolean;
  isPriceRequired?: boolean;
  isGuaranteeRequired?: boolean;
  companyId?: string;
  inventoryPrefix?: string;
  smallEquipments?: string;
}

const initialValues: FormData = {
  name: '',
  isDateOfPurchaseRequired: false,
  isInventoryNumberRequired: false,
  isSerialNumberRequired: false,
  isPriceRequired: false,
  isGuaranteeRequired: false,
  inventoryPrefix: '',
  smallEquipments: 'normal',
};

interface ParametresDetail {
  name: string;
  key: string;
  isRequired: boolean;
}

const renderParametres = (parametrs: ParametresDetail[]) => {
  return parametrs.map((param) =>
    Object.assign({
      size: 12,
      field: (
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={12}>
            <Field
              name={`is${param.key}Required`}
              type="chekcbox"
              component={(props) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={props.input.name}
                      disabled={param.isRequired}
                      checked={param.isRequired ? true : undefined}
                      onChange={props.input.onChange}
                      data-testid="hw-parameter-checkbox"
                    />
                  }
                  label={param.name}
                />
              )}
            ></Field>
          </Grid>
        </Grid>
      ),
    })
  );
};

const validate = (values: FormData) => {
  const errors = Validations.new(values).required('name').getErrors();

  return errors;
};

export const AddHardwareTypeDialog: FC<AddHardwareTypeDialogProps> = (
  props
) => {
  const { onClose, onSubmit } = props;
  const { userData } = useContext(UserContext);
  const { activeOwners = [] } = useContext(OwnerContext);
  const [isSmallEquipment, setIsSmallEquipment] = useState(true);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const formFields: FormFields[] = [
    {
      size: 12,
      field: (
        <TextField
          required
          type="text"
          name="name"
          label={<Trans i18nKey="hwTypeForm.description" />}
          margin="none"
          inputProps={{ 'data-testid': 'hw-type-name' }}
        />
      ),
    },
  ];

  const parametrs: ParametresDetail[] = [
    {
      name: t('hwTypeForm.requiredPurchaseDate') as string,
      key: 'DateOfPurchase',
      isRequired: initialValues.isDateOfPurchaseRequired!,
    },
    {
      name: t('hwTypeForm.requiredQuaranteeDate') as string,
      key: 'Guarantee',
      isRequired: initialValues.isGuaranteeRequired!,
    },

    {
      name: t('hwTypeForm.requiredPrice') as string,
      key: 'Price',
      isRequired: initialValues.isPriceRequired!,
    },
  ];

  if (isSmallEquipment) {
    formFields.push({
      size: 12,
      field: (
        <TextField
          required
          type="text"
          name="inventoryPrefix"
          label={<Trans i18nKey="hwTypeForm.inventoryNumberPrefix" />}
          margin="none"
          inputProps={{ 'data-testid': 'hw-prefix' }}
        />
      ),
    });
    parametrs.push(
      {
        name: t('hwTypeForm.requiredInventaryNumber') as string,
        key: 'InventoryNumber',
        isRequired: initialValues.isInventoryNumberRequired!,
      },
      {
        name: t('hwTypeForm.requiredSerialNumber') as string,
        key: 'SerialNumber',
        isRequired: initialValues.isSerialNumberRequired!,
      }
    );
  }

  const parametersFileds = renderParametres(parametrs) || [];

  const _handleSubmit = async (values: FormData, form: FormApi) => {
    if (
      values.smallEquipments === 'normal' &&
      (!values.inventoryPrefix || values.inventoryPrefix.length > 10)
    ) {
      enqueueSnackbar(
        <Trans i18nKey="notifications.inventoryPrefixMustBeLessThanTenChar" />,
        {
          variant: 'error',
        }
      );
      return;
    }
    try {
      const res = await fetch('/api/hardware/type', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'POST',
        body: JSON.stringify(values),
      }).then((data) => data.json());
      if (res.error) {
        return;
      }
      onSubmit(res);
    } catch (exception) {
      console.log(exception);
    }
  };

  const currentInitialValues: FormData = {
    ...initialValues,
    companyId:
      props.selectedCompany ??
      (activeOwners?.length === 1 ? activeOwners[0]?._id : undefined),
  };

  const isCompanyFieldDisabled = Boolean(props.selectedCompany);

  return (
    <Dialog title={<Trans i18nKey="hwTypeForm.title" />} onClose={onClose}>
      <Form
        validate={validate}
        onSubmit={_handleSubmit}
        initialValues={currentInitialValues}
        render={({ handleSubmit, submitting }) => (
          <Paper elevation={0}>
            <form onSubmit={handleSubmit} noValidate>
              <Grid item xs={12} marginBottom="16px">
                <Field name="companyId">
                  {(props) => (
                    <OwnerSelectField
                      value={props.input.value}
                      onChange={(company?: Company) => {
                        props.input.onChange(company?._id);
                      }}
                      error={
                        props.meta.submitFailed ? props.meta.error : undefined
                      }
                      disabled={isCompanyFieldDisabled}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12} marginBottom="16px">
                <Field name="smallEquipments">
                  {(props) => {
                    return (
                      <TypeEquipmentSelect
                        onChange={(smallEquipment?: string) => {
                          props.input.onChange(smallEquipment);
                          setIsSmallEquipment(smallEquipment === 'normal');
                        }}
                      />
                    );
                  }}
                </Field>
              </Grid>

              <Grid container alignItems="flex-start" spacing={2}>
                {formFields.concat(parametersFileds).map((item, idx) => (
                  <Grid item xs={item.size} key={idx}>
                    {item.field}
                  </Grid>
                ))}

                <Grid
                  item
                  xs={12}
                  position={{ xs: 'sticky', lg: 'relative' }}
                  bottom={{ xs: '-16px' }}
                  bgcolor={{ xs: 'white' }}
                  paddingBottom="16px"
                  sx={{
                    marginTop: '8px',
                  }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    spacing={{ xs: 0, lg: 2 }}
                    sx={{
                      maxWidth: '100%',
                      width: '100%',

                      background: '#fff',
                    }}
                  >
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitting}
                        data-testid="submit-add-hw-btn"
                        sx={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.send" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <Button
                        type="button"
                        variant="text"
                        onClick={onClose}
                        data-testid="cancel-add-hw-btn"
                        sx={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        <Trans i18nKey="labels.cancel" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        )}
      />
    </Dialog>
  );
};
