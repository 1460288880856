import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { Field } from 'react-final-form';
import { Grid, styled } from '@mui/material';
import { DatePicker, TextField } from 'mui-rff';
import { format } from 'date-fns';

import { CountUnitSelect } from './CountUnitSelect';
import { Hardware, HardwareRevisionType, PlacementType } from '../commonTypes';
import { RevisionTypeSelectField, PlacementSelectField } from '../components';
import { StyledGrid, StyledTextField } from '../customStyledComponents';

const StyledField = styled(TextField)(({ theme }) => ({
  padding: '16px 0px 16px 0px',
  border: 'unset',
  width: '100%',
  fontSize: '14px',
}));

interface EditHwFormFieldsProps {
  formValues: Hardware;
  isPremium: boolean;
  isCompanyVATincluded?: boolean;
  handleRevisionDate: (
    valuesForm: Hardware,
    revision: HardwareRevisionType | null
  ) => void;
}

export const EditHwFormFields: FC<EditHwFormFieldsProps> = (props) => {
  const { formValues, isPremium, isCompanyVATincluded, handleRevisionDate } =
    props;
  return (
    <Grid xs={12} sx={{ marginBottom: '20px' }}>
      <StyledGrid>
        <Grid container>
          <Grid item xs={6} alignItems="center" display="flex">
            <Trans i18nKey="editHwForm.description" />*
          </Grid>
          <Grid item xs={6}>
            <StyledField
              inputProps={{
                style: { textAlign: 'left', fontSize: '14px' },
              }}
              type="text"
              name="description"
              margin="none"
              fullWidth
              required
              variant="standard"
            />
          </Grid>
        </Grid>
      </StyledGrid>

      <StyledGrid>
        <Grid container>
          <Grid item xs={6} alignItems="center" display="flex">
            <Trans i18nKey="editHwForm.type" />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              inputProps={{ style: { textAlign: 'right' } }}
              type="text"
              name="type.name"
              margin="none"
              fullWidth
              required
              variant="standard"
              component="input"
              disabled
            ></StyledTextField>
          </Grid>
        </Grid>
      </StyledGrid>

      <StyledGrid>
        <Grid container>
          <Grid item xs={6} alignItems="center" display="flex">
            <Trans i18nKey="editHwForm.borrowed" />
          </Grid>
          <Grid item xs={6}>
            <StyledTextField
              inputProps={{ style: { textAlign: 'right' } }}
              type="text"
              name="borrowedBy"
              margin="none"
              fullWidth
              required
              variant="standard"
              component="input"
              disabled
            ></StyledTextField>
          </Grid>
        </Grid>
      </StyledGrid>

      <StyledGrid>
        <Grid container>
          <Grid item xs={6} alignItems="center" display="flex">
            <Trans i18nKey="editHwForm.purchaseDate" />
            {formValues.type?.isDateOfPurchaseRequired ? '*' : null}
          </Grid>
          <Grid xs={6}>
            <DatePicker
              inputFormat="dd.MM.yyyy"
              name="dateOfPurchase"
              required={formValues.type.isDateOfPurchaseRequired}
            />
          </Grid>
        </Grid>
      </StyledGrid>

      <StyledGrid>
        <Grid container>
          <Grid item xs={6} alignItems="center" display="flex">
            <Trans i18nKey="editHwForm.guaranteeDate" />
            {formValues.type?.isGuaranteeRequired ? '*' : null}
          </Grid>
          <Grid xs={6}>
            <DatePicker
              inputFormat="dd.MM.yyyy"
              name="guaranteeUntil"
              required={formValues.type.isGuaranteeRequired}
            />
          </Grid>
        </Grid>
      </StyledGrid>

      <StyledGrid>
        <Grid container>
          <Grid item xs={6} alignItems="center" display="flex">
            <Trans i18nKey="editHwForm.revisionDate" />
          </Grid>
          <Grid xs={6} sx={{ padding: '16px 0 16px 0' }}>
            <StyledTextField
              type="text"
              name="revisionNext"
              margin="none"
              fullWidth
              required
              variant="standard"
              component="input"
              disabled
            >
              {(pr) => format(new Date(pr.input.value as string), 'dd.MM.yyyy')}
            </StyledTextField>
          </Grid>
        </Grid>
      </StyledGrid>

      <StyledGrid>
        <Grid container>
          <Grid item xs={6} alignItems="center" display="flex">
            <Trans i18nKey="editHwForm.revisionType" />
          </Grid>
          <Grid item xs={6}>
            <Field name="revisionType">
              {(props) => (
                <RevisionTypeSelectField
                  disableTitle={true}
                  disabled={!isPremium}
                  companyId={formValues.companyId}
                  value={
                    typeof props.input.value === 'string'
                      ? props.input.value
                      : props.input.value?._id
                  }
                  defaultValue={formValues.revisionType?._id}
                  onChange={(revision: HardwareRevisionType | null) => {
                    props.input.onChange(revision);
                    handleRevisionDate(formValues, revision);
                  }}
                  error={props.meta.submitFailed ? props.meta.error : undefined}
                  showAddNew={true}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </StyledGrid>

      <StyledGrid>
        <Grid container>
          <Grid item xs={6} alignItems="center" display="flex">
            <Trans i18nKey="editHwForm.placement" />
          </Grid>
          <Grid item xs={6}>
            <Field name="placement">
              {(props) => (
                <PlacementSelectField
                  disableTitle={true}
                  companyId={formValues.companyId}
                  value={
                    typeof props.input.value === 'string'
                      ? props.input.value
                      : props.input.value?._id
                  }
                  onChange={(placement: PlacementType | null) => {
                    props.input.onChange(placement);
                  }}
                  error={props.meta.submitFailed ? props.meta.error : undefined}
                  showAddNew={true}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </StyledGrid>

      {!formValues.type?.smallEquipments ||
      formValues.type?.smallEquipments === 'normal' ? (
        <StyledGrid>
          <Grid container>
            <Grid item xs={6} alignItems="center" display="flex">
              <Trans i18nKey="editHwForm.inventoryNumber" />
              {formValues.type?.isInventoryNumberRequired ? '*' : null}
            </Grid>
            <Grid xs={6}>
              <StyledTextField
                inputProps={{ style: { textAlign: 'right' } }}
                type="text"
                name="inventoryNumber"
                margin="none"
                fullWidth
                required={formValues.type.isInventoryNumberRequired}
                variant="standard"
                component="input"
                disabled
              ></StyledTextField>
            </Grid>
          </Grid>
        </StyledGrid>
      ) : null}

      <StyledGrid>
        <Grid container>
          <Grid item xs={6} alignItems="center" display="flex">
            {isCompanyVATincluded ? (
              <Trans i18nKey="editHwForm.priceVATincluded" />
            ) : (
              <Trans i18nKey="editHwForm.priceWithoutVAT" />
            )}

            {formValues.type?.isPriceRequired ? '*' : null}
          </Grid>
          <Grid xs={6}>
            <StyledField
              inputProps={{
                style: { textAlign: 'left', fontSize: '14px' },
              }}
              type="text"
              name="price"
              margin="none"
              fullWidth
              required={formValues.type.isPriceRequired}
              variant="standard"
            />
          </Grid>
        </Grid>
      </StyledGrid>

      {!formValues.type?.smallEquipments ||
      formValues.type?.smallEquipments === 'normal' ? (
        <StyledGrid>
          <Grid container>
            <Grid item xs={6} alignItems="center" display="flex">
              <Trans i18nKey="editHwForm.serialNumber" />
              {formValues.type?.isSerialNumberRequired ? '*' : null}
            </Grid>
            <Grid xs={6}>
              <StyledField
                inputProps={{
                  style: { textAlign: 'left', fontSize: '14px' },
                }}
                type="text"
                name="serialNumber"
                margin="none"
                fullWidth
                required={formValues.type.isSerialNumberRequired}
                variant="standard"
              />
            </Grid>
          </Grid>
        </StyledGrid>
      ) : null}

      {formValues.type?.smallEquipments === 'small' ? (
        <>
          <StyledGrid>
            <Grid container>
              <Grid item xs={6} alignItems="center" display="flex">
                <Trans i18nKey="editHwForm.count" />
              </Grid>
              <Grid xs={6}>
                <StyledTextField
                  inputProps={{ style: { textAlign: 'right' } }}
                  type="text"
                  name="count"
                  margin="none"
                  fullWidth
                  variant="standard"
                  component="input"
                />
              </Grid>
            </Grid>
          </StyledGrid>
          <StyledGrid>
            <Grid container>
              <Grid item xs={6} alignItems="center" display="flex">
                <Trans i18nKey="editHwForm.countUnit" />
              </Grid>
              <Grid xs={6}>
                <Field name="countUnit">
                  {(props) => (
                    <CountUnitSelect
                      defaultValue={formValues.countUnit}
                      onChange={(countUnit: string | undefined) => {
                        props.input.onChange(countUnit);
                      }}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
          </StyledGrid>
        </>
      ) : null}
    </Grid>
  );
};
