import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { FileForm } from './FileForm';
import { Dialog } from '../components';

interface FileDialogProps {
  onClose: () => void;
  hwId?: string;
}

export const FileDialog: FC<FileDialogProps> = (props) => {
  const { onClose, hwId } = props;

  return (
    <Dialog title={<Trans i18nKey="fileDialog.title" />} onClose={onClose}>
      <FileForm onCancel={onClose!} hwId={hwId} />
    </Dialog>
  );
};
