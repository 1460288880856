import React, { FC, useCallback, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Box,
} from '@mui/material';

import { HardwareRevisionTypeContext } from '../../contexts';
import { HardwareRevisionType } from '../../commonTypes';
import { AddHardwareRevisionDialog } from '../../hardwareRevisionType';

interface RevisionTypeSelectFieldProps {
  error?: string | boolean;
  value?: string;
  disabled?: boolean;
  defaultValue?: string;
  onChange: (revision: HardwareRevisionType | null) => void;
  companyId?: string;
  disableTitle?: boolean;
  showAddNew?: boolean;
}

const renderItems = (revisions: HardwareRevisionType[] = []) => {
  return revisions.map((item) => (
    <MenuItem
      key={item._id}
      value={item._id}
      data-testid={`revision-select-option-${item.name}`}
    >
      {item.name}
    </MenuItem>
  ));
};

export const RevisionTypeSelectField: FC<RevisionTypeSelectFieldProps> = (
  props
) => {
  const {
    onChange,
    companyId,
    defaultValue,
    disableTitle,
    disabled,
    error,
    showAddNew,
    value,
  } = props;

  const { globalHardwareRevisionType, refreshHardwareRevisionType } =
    useContext(HardwareRevisionTypeContext);
  const [showAddRevisionDialog, setShowAddRevisionDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const options = renderItems(
    globalHardwareRevisionType?.filter(
      (a) => a.companyId === props.companyId
    )
  );
  
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    const selectedRevision = globalHardwareRevisionType?.find(
      ({ _id: id }) => id === selectedValue
    );

    onChange(selectedRevision ? selectedRevision : null);
  };

  const handleOnSubmitNewRevision = useCallback(
    async (revision: HardwareRevisionType | null) => {
      try {
        await refreshHardwareRevisionType?.();
        setShowAddRevisionDialog(false);
        setIsOpen(false);
        onChange(revision);
      } catch (error) {
        console.log(error);
      }
    },
    [globalHardwareRevisionType, refreshHardwareRevisionType] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      {showAddRevisionDialog ? (
        <AddHardwareRevisionDialog
          onClose={() => {
            onChange(null);
            setShowAddRevisionDialog(false);
          }}
          onSubmit={handleOnSubmitNewRevision}
          selectedCompany={companyId}
        />
      ) : null}

      <FormControl error={!!error} fullWidth>
        <FormControl fullWidth disabled={disabled}>
          {!disableTitle && (
            <InputLabel id="revision">
              <Trans i18nKey="labels.revisionType" />
            </InputLabel>
          )}
          <Select
            defaultValue={defaultValue}
            labelId="revision"
            id="revision-select"
            label={
              !disableTitle ? <Trans i18nKey="labels.revisionType" /> : null
            }
            onChange={handleChange}
            value={value}
            data-testid="revision-select-field"
            inputProps={{ value: value ?? '' }}
            open={isOpen}
            onClick={() => !disabled && setIsOpen((prev) => !prev)}
          >
            <MenuItem value={-1}>
              <Trans i18nKey="labels.select" />
            </MenuItem>
            {options}
            {showAddNew && (
              <Box
                onClick={() => setShowAddRevisionDialog(true)}
                sx={{
                  '&:last-of-type': {
                    color: 'primary.dark',
                    padding: '8px 16px',
                    cursor: 'pointer',
                    fontWeight: '500',
                    borderTop: (theme) =>
                      '1px solid ' + theme.palette.warning.light,
                    '&:hover': { bgcolor: 'warning.light' },
                  },
                }}
              >
                <Trans i18nKey="labels.addNewRevisionType" />
              </Box>
            )}
          </Select>
        </FormControl>
      </FormControl>
    </>
  );
};
