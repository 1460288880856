import React from 'react';
import {
  DashboardOutlined,
  PlaceOutlined,
  List as ListIcon,
  GroupOutlined as GroupOutlinedIcon,
  Devices as DevicesIcon,
  CorporateFare as CorporateFareIcon,
  Grading as GradingIcon,
} from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { MenuItemButtonsType } from './interfaces';

export const menuIcons = {
  dashboard: DashboardOutlined,
  list: ListIcon,
  users: GroupOutlinedIcon,
  devices: DevicesIcon,
  building: CorporateFareIcon,
  gradingIcon: GradingIcon,
  placement: PlaceOutlined,
};

export const menuItemButtons: MenuItemButtonsType[] = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
  },
  {
    label: <Trans i18nKey="menu.menuHWList" />,
    path: '/hardware-list',
    icon: 'list',
  },
  {
    label: <Trans i18nKey="menu.menuUsers" />,
    path: '/users',
    icon: 'users',
  },
  {
    label: <Trans i18nKey="menu.menuHWTypes" />,
    path: '/hardware-type',
    icon: 'devices',
  },
  {
    label: <Trans i18nKey="menu.menuRevisionCategory" />,
    path: '/hardware-revision-type',
    icon: 'gradingIcon',
  },
  {
    label: <Trans i18nKey="menu.menuPlacement" />,
    path: '/hardware-placement',
    icon: 'placement',
  },
  {
    label: <Trans i18nKey="menu.menuUserOverview" />,
    path: '/users-list',
    icon: 'users',
  },
  {
    label: <Trans i18nKey="menu.menuCompanies" />,
    path: '/companies',
    icon: 'building',
  },
];
