import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { FormApi } from 'final-form';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import { Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

import { UserContext } from '../contexts';

interface AddFileFormProps {
  onCancel: () => void;
  hwId?: string;
  refresh: () => void;
}

interface FormData {
  fileDescription?: string;
}

export const AddFileForm: FC<AddFileFormProps> = (props) => {
  const { onCancel, refresh, hwId } = props;
  const { userData } = useContext(UserContext);
  const [file, setFile] = useState<File>();
  const { enqueueSnackbar } = useSnackbar();

  const onChange = async (
    event: ChangeEvent<HTMLInputElement>,
    form: FormApi<any, Partial<any>>
  ): Promise<void> => {
    if (event.target.files) {
      const files = event.target.files[0];
      setFile(files);
      if (!form.getFieldState('fileDescription')?.value)
        form.mutators.setTitle(files.name);
    }
  };

  const getLimitedFileName = () => {
    if (file && file.name.length > 10) {
      const fileName = file?.name.substring(0, 15) + '...';
      return fileName;
    }
  };

  const handleSubmit = async (values: FormData) => {
    const data = new FormData();
    if (file) {
      data.append('file', file);
      const fileFormat = file.name.slice(file.name.lastIndexOf('.') + 1);
      data.append('fileFormat', fileFormat);
      if (!values.fileDescription) {
        data.append('name', file.name);
      } else {
        data.append('name', values.fileDescription);
      }
    } else {
      enqueueSnackbar(
        <Trans i18nKey="notifications.errorYouMustUploadFile" />,
        {
          variant: 'error',
        }
      );
      return;
    }

    if (file.size > 10000000) {
      enqueueSnackbar(<Trans i18nKey="notifications.fileOverLimit" />, {
        variant: 'error',
      });
      return;
    }

    try {
      const res = await fetch(`/api/documents/documents/${hwId}`, {
        headers: {
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'POST',
        body: data,
      }).then((data) => data.json());
      if (res.error) {
        enqueueSnackbar(`${res.error}`, {
          variant: 'error',
        });
        return;
      }
      enqueueSnackbar(<Trans i18nKey="notifications.saved" />, {
        variant: 'success',
      });

      onCancel();
      refresh();
    } catch (exception) {
      console.log(exception);
    }
  };

  const initialValues: FormData = {
    fileDescription: '',
  };

  return (
    <Form
      mutators={{
        setTitle: (args, state, utils) => {
          utils.changeValue(state, 'fileDescription', () => args[0]);
        },
      }}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit: handleSubmitFile, values, form, pristine }) => {
        return (
          <form onSubmit={handleSubmitFile}>
            <Grid container alignItems="flex-start">
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <label
                    htmlFor="uploadedFile"
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <strong>
                      <Trans i18nKey="addFileForm.uploadFile" />
                    </strong>
                    <input
                      type="file"
                      name="uploadedFile"
                      id="uploadedFile"
                      onChange={(e) => onChange(e, form)}
                      style={{
                        opacity: 0,
                      }}
                    />
                    {file?.name ? (
                      <label htmlFor="uploadedFile">
                        {file?.name.length > 10
                          ? getLimitedFileName()
                          : file?.name}
                      </label>
                    ) : (
                      <label htmlFor="uploadedFile">
                        <Trans i18nKey="addFileForm.chooseFile" />
                        ...
                      </label>
                    )}
                    <UploadFileOutlinedIcon color="primary" />
                  </label>
                </Grid>
                <div
                  style={{
                    height: '1px',
                    background: 'rgba(0, 0, 0, 0.12)',
                    width: 'calc(100% + 32px)',
                    margin: '0 -16px',
                    marginTop: '8px',
                    marginBottom: '24px',
                  }}
                />
                <Grid item xs={12}>
                  <TextField
                    label={<Trans i18nKey="addFileForm.description" />}
                    margin="none"
                    name="fileDescription"
                    type="text"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 16 }}>
                <Grid container justifyContent="center" xs={12}>
                  <Grid item xs={12}>
                    <Button
                      type="button"
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        handleSubmit(values);
                        refresh();
                      }}
                      disabled={pristine}
                      sx={{
                        marginBottom: '8px',
                      }}
                      data-testid="upload-file-btn"
                    >
                      <Trans i18nKey="addFileForm.save" />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="button" fullWidth onClick={onCancel}>
                      <Trans i18nKey="addFileForm.back" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};
