import React, { FC, useCallback, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import {
  Select,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
  FormControl,
  Box,
} from '@mui/material';

import { AddHardwareTypeDialog } from './AddHardwareTypeDialog';
import { TypesContext } from '../contexts';
import { HardwareType, SelectProps } from '../commonTypes';

function renderItems(types: HardwareType[] = []) {
  return types.map((item) => (
    <MenuItem
      key={item._id}
      value={item._id}
      disabled={item.isDeleted}
      data-testid={`hw-type-select-option-${item.name}`}
    >
      {item.name}
    </MenuItem>
  ));
}

export const TypesHardwareSelectField: FC<
  SelectProps<HardwareType | undefined> & { showAddNew?: boolean }
> = (props) => {
  const { onChange, error, selectedValue, showAddNew, value } = props;

  const { types = [], refreshTypes } = useContext(TypesContext);

  const [showAddTypeDialog, setShowAddTypeDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedId = event.target.value;
    const selectedType = types.find(({ _id: id }) => id === selectedId);
    onChange(selectedType);
  };

  const isDisabled = !selectedValue;

  const typesByCompany = types.filter(
    (type) => type.companyId === selectedValue
  );

  const handleOnSubmitNewHwType = useCallback(
    async (hwType: HardwareType) => {
      try {
        await refreshTypes?.();
        onChange(hwType);
        setShowAddTypeDialog(false);
        setIsOpen(false);
      } catch (error) {
        console.log(error);
      }
    },
    [types, refreshTypes] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      {showAddTypeDialog && (
        <AddHardwareTypeDialog
          onClose={() => {
            onChange(undefined);
            setShowAddTypeDialog(false);
          }}
          onSubmit={handleOnSubmitNewHwType}
          selectedCompany={selectedValue}
        />
      )}

      <FormControl error={!!error} fullWidth>
        <FormControl fullWidth disabled={isDisabled}>
          <InputLabel id="type">
            <Trans i18nKey="labels.hwType" />
          </InputLabel>
          <Select
            labelId="type"
            id="type-select"
            value={value as string}
            label="Typ hardware"
            onChange={handleChange}
            data-testid="hw-type-select-field"
            displayEmpty={false}
            inputProps={{ value: value ?? '' }}
            open={isOpen}
            onClick={() => !isDisabled && setIsOpen((prev) => !prev)}
          >
            <MenuItem value={-1}>
              <Trans i18nKey="labels.select" />
            </MenuItem>
            {renderItems(typesByCompany)}
            {showAddNew && (
              <Box
                onClick={() => setShowAddTypeDialog(true)}
                sx={{
                  '&:last-of-type': {
                    color: 'primary.dark',
                    padding: '8px 16px',
                    cursor: 'pointer',
                    fontWeight: '500',
                    borderTop: (theme) =>
                      '1px solid ' + theme.palette.warning.light,
                    '&:hover': { bgcolor: 'warning.light' },
                  },
                }}
              >
                <Trans i18nKey="labels.addHwType" />
              </Box>
            )}
          </Select>
        </FormControl>
      </FormControl>
    </>
  );
};
