import React, { FC, ReactText, useCallback, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { format } from 'date-fns';
import MaterialTable, { Column } from '@material-table/core';

import { CompanyContext, HardwareContext, UserContext } from '../contexts';

import { useMaterialTableLocalization } from '../hooks';
import { useGetTableRows, createTableIcons, tableEnum } from '../utils';
import { Company, Hardware } from '../commonTypes';
import {
  ButtonWithoutShadow,
  CustomTablePagination,
} from '../customStyledComponents';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { t } from 'i18next';
import ConstructionIcon from '@mui/icons-material/Construction';
import { useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { PaymentDialog } from 'src/components';
import { RevisionDialog } from 'src/hardware';

interface TableState {
  columns: Array<Column<Hardware>>;
  data: Hardware[];
}

export const UpcomingRevisionsTable: FC<{
  onCallbackDetail: (hardwareId: string, hardwareDetail: boolean) => void;
}> = ({ onCallbackDetail }) => {
  const { globalUserCompany = [], setCompanyUpdate } =
    useContext(CompanyContext);
  const { nextMonthRevisionHardware } = useContext(HardwareContext);
  const { changeTableRows, userData } = useContext(UserContext);
  const localization = useMaterialTableLocalization();
  const upcomingRevisionTableIcons = createTableIcons('upcoming-revision');
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [showRevisionDialog, setShowRevisionDialog] = useState<ReactText>();
  const { enqueueSnackbar } = useSnackbar();
  const [showPaymentDialog, setShowPaymentDialog] = useState<ReactText>();
  const [companyData, setCompanyData] = useState<Company>({
    _id: '',
    confirmBorrowing: false,
    companyName: '',
    isDeleted: false,
    licenceType: '',
    licenceValidTo: '',
    users: [],
    licenceLastRequestAt: '',
    companyVat: '',
  });

  const findCompanyById = (companyId?: string) => {
    return globalUserCompany?.find((gac) => gac._id === companyId)?.companyName;
  };

  const disabledButtonForNonPremiumUser = globalUserCompany.filter(
    (guc) =>
      guc.licenceType !== 'premium' || new Date(guc.licenceValidTo) < new Date()
  );

  const isEditableRow = useCallback(
    (row: Hardware) => {
      const foundCompany = globalUserCompany.find(
        (comp) => comp._id === row.companyId
      );
      const foundUser = foundCompany?.users.find(
        (u) => u.uid === userData?.user._id
      );
      if (foundUser?.userRole === 'User') {
        return false;
      } else {
        return true;
      }
    },
    [globalUserCompany, userData]
  );

  const paymentDialogSubmit = () => {
    setCompanyUpdate?.(true);
    setShowPaymentDialog(undefined);
  };

  const findCompanyData = (row: Hardware) => {
    const foundCompany = globalUserCompany.find(
      (comp) => comp._id === row.companyId
    );
    if (foundCompany) {
      setCompanyData(foundCompany);
    }
  };

  const toggleRevisionDialog = (index?: ReactText) => () => {
    setShowRevisionDialog(index);
  };

  const dataTable: TableState = {
    columns: [
      {
        title: <Trans i18nKey="tables.upcomingRevisionTable.companyName" />,
        field: 'companyName',
        render: (row) => {
          const companyName = globalUserCompany?.find(
            (gac) => gac._id === row.companyId
          )?.companyName;
          return <div>{companyName || '--'}</div>;
        },
      },
      {
        title: <Trans i18nKey="tables.upcomingRevisionTable.hardware" />,
        field: 'description',
        render: (row) => {
          return <div>{row.description || '--'}</div>;
        },
      },
      {
        title: <Trans i18nKey="tables.upcomingRevisionTable.nextRevision" />,
        field: 'revisionNext',
        render: (row) => {
          const date = row?.revisionNext
            ? format(new Date(row?.revisionNext), 'dd.MM.yyyy')
            : undefined;
          return <div>{date || '--'}</div>;
        },
      },
    ],
    data: nextMonthRevisionHardware.map((hw) => ({
      ...hw,
      companyName: findCompanyById(hw?.companyId),
    })),
  };

  return (
    <div>
      <MaterialTable
        title={<Trans i18nKey="tables.upcomingRevisionTable.description" />}
        localization={localization}
        icons={upcomingRevisionTableIcons}
        columns={dataTable.columns}
        data={dataTable.data ?? []}
        components={{
          Pagination: CustomTablePagination,
        }}
        options={{
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          pageSize: useGetTableRows(tableEnum.upcomingRevisionTable),
        }}
        onRowsPerPageChange={(value) => {
          changeTableRows?.(tableEnum.upcomingRevisionTable, value);
        }}
        actions={[
          (values) => {
            const isEditable = isEditableRow(values);
            return {
              hidden: xsDown || !isEditable,
              icon: () => (
                <ButtonWithoutShadow
                  variant="contained"
                  color="primary"
                  disabled={!isEditable}
                  data-testid={`confirmRevision-btn-${values._id}`}
                >
                  <ConstructionIcon />
                </ButtonWithoutShadow>
              ),

              tooltip: t('tables.hwList.confirmRevision') as string,
              onClick: (_event, row) => {
                const unPaidPremium =
                  disabledButtonForNonPremiumUser.filter(
                    (f) => f._id === row.companyId
                  ).length > 0;
                if (unPaidPremium) {
                  findCompanyData(row);
                  setShowPaymentDialog(row._id);
                  enqueueSnackbar(
                    <Trans i18nKey="notifications.revisionOnlyForPremiumCompanies" />,
                    {
                      variant: 'error',
                    }
                  );
                  return;
                }
                console.log(row, 'row');

                setShowRevisionDialog(row?._id);
              },
            };
          },

          (rowData) => ({
            icon: () => (
              <ButtonWithoutShadow
                variant="contained"
                color="secondary"
                sx={{ color: '#496AEC' }}
              >
                <InfoOutlinedIcon />
              </ButtonWithoutShadow>
            ),
            tooltip: t('tables.hwList.detail') as string,
            onClick: (_event, row) => {
              if (!Array.isArray(row)) {
                onCallbackDetail(row._id, true);
              }
            },
          }),
        ]}
      />
      {showPaymentDialog ? (
        <PaymentDialog
          companyData={companyData}
          onSubmit={() => paymentDialogSubmit()}
          onClose={() => setShowPaymentDialog(undefined)}
        />
      ) : null}
      {showRevisionDialog && (
        <RevisionDialog
          values={nextMonthRevisionHardware.find(
            (hw) => hw._id === showRevisionDialog
          )}
          onClose={toggleRevisionDialog(undefined)}
        />
      )}
    </div>
  );
};
