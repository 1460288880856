import React, { FC, useContext } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';

import { Dialog } from '../Dialog';
import { CompanyContext } from '../../contexts';
import { Company } from '../../commonTypes';
import { Trans } from '../../i18n';
import { StyledButton } from '../../customStyledComponents';

interface PDFDialogProps {
  onClose: () => void;
  onSubmit: (guc?: Company) => void;
}

export const PdfDialog: FC<PDFDialogProps> = (props) => {
  const { onClose, onSubmit } = props;

  const { globalUserCompany = [] } = useContext(CompanyContext);

  const disabledExport = globalUserCompany.filter(
    (guc) => guc.licenceType !== 'premium'
  );

  return (
    <div>
      <Dialog
        title={<Trans i18nKey="pdfDialog.title" />}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid>
          <DialogTitle id="alert-dialog-title">
            {<Trans i18nKey="pdfDialog.description" />}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {globalUserCompany.map((guc) => (
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  paddingBottom="8px"
                  xs={12}
                  data-testid={`company-${guc._id}`}
                >
                  <Grid item xs={6}>
                    - {guc.companyName}(
                    {guc.licenceType ? guc.licenceType : 'free'})
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="flex-end">
                    {guc.licenceType === 'premium' &&
                    new Date(guc.licenceValidTo) > new Date() ? (
                      <Button
                        type="button"
                        color="secondary"
                        variant="contained"
                        style={{ boxShadow: 'none', color: '#496AEC' }}
                        onClick={() => {
                          onSubmit(guc);
                        }}
                        data-testid="download-pdf-btn"
                      >
                        <Trans i18nKey="labels.exportPDF" />
                      </Button>
                    ) : (
                      <Grid>
                        {
                          <Trans i18nKey="pdfDialog.exportIsOnlyAvailableInPremiumVersion" />
                        }
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={onClose}
              data-testid="close-pdf-modal-btn"
            >
              {<Trans i18nKey="pdfDialog.close" />}
            </Button>
            <StyledButton
              type="button"
              color="secondary"
              variant="contained"
              style={{ boxShadow: 'none', color: '#496AEC' }}
              onClick={() => onSubmit()}
              disabled={disabledExport.length > 0 ? true : false}
            >
              <Trans i18nKey="pdfDialog.exportPDF" />
            </StyledButton>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  );
};
