import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import { AddFileForm } from './AddFileForm';
import { Dialog } from '../components';

interface AddFileDialogProps {
  onClose: () => void;
  hwId?: string;
  refresh: () => void;
}

export const AddFileDialog: FC<AddFileDialogProps> = (props) => {
  const { onClose, hwId, refresh } = props;

  return (
    <Dialog title={<Trans i18nKey="addFileDialog.title" />} onClose={onClose}>
      <AddFileForm onCancel={onClose!} hwId={hwId} refresh={refresh!} />
    </Dialog>
  );
};
