import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  FC,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Company, Currency } from '../commonTypes';

interface User {
  user: {
    _id: string;
    email: string;
    token: string;
  };
  userInfo: {
    _id: string;
    email: string;
    preferredLanguage: string;
    tableSetting: Array<{ key: string; value: number }>;
  };
  userCompanies: Company[];
}

export const UserContext = createContext<
  Partial<{
    userData: User | null;
    isLoading: boolean;
    setUser: Dispatch<SetStateAction<null>>;
    logout: () => void;
    changeLanguage: (id: string) => Promise<void>;
    setLanguage: (id: string) => Promise<void>;
    lang: string;
    defaultCurrency: Currency | null;
    changeTableRows: (key: string, value: number) => Promise<void>;
  }>
>({
  userData: null,
  setUser: () => {},
  logout: () => {},
  defaultCurrency: null,
});

export const UserProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { i18n } = useTranslation();
  const [userData, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lang, setLang] = useState<string>(() => {
    const preferredLanguage = localStorage.getItem('preferredLanguage');
    if (preferredLanguage && ['cs', 'en', 'bg'].includes(preferredLanguage)) {
      return preferredLanguage;
    } else {
      const browserLanguage = navigator.language.slice(0, 2);
      if (['cs', 'en', 'bg'].includes(browserLanguage)) {
        return browserLanguage;
      } else {
        return 'en';
      }
    }
  });
  const [defaultCurrency, setDefaultCurrency] = useState<Currency | null>(null);

  useEffect(() => {
    if (lang) {
      if (lang.includes('cs')) {
        setDefaultCurrency('CZK');
      } else if (lang.includes('en')) {
        setDefaultCurrency('EUR');
      } else if (lang.includes('bg')) {
        setDefaultCurrency('BGN');
      }
    }
  }, [lang]);

  const saveUser = useCallback((userData) => {
    window.localStorage.setItem('token', userData.user.token);
    setUser(userData);
  }, []); // eslint-disable-line

  const logout = () => {
    window.localStorage.removeItem('token');

    setUser(null);

    window.location.replace(window.location.origin);
  };

  const setLanguage = useCallback(
    async (id: string) => {
      await i18n.changeLanguage(id);
    },
    [i18n]
  );

  const changeLanguage = useCallback(
    async (id: string) => {
      if (userData) {
        await fetch('/api/users/user/info', {
          body: JSON.stringify({
            preferredLanguage: id,
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData.user.token}`,
          },
          method: 'PATCH',
        }).then(async (data) => {
          const response = await data.json();
          setUser((prev) => ({ ...prev, userInfo: response.userInfo } as User));
        });
      }

      await setLanguage(id);
      setLang(id);
    },
    [userData, setLanguage]
  );

  const refreshUser = useCallback(async () => {
    const token = window.localStorage.getItem('token');

    let res = null;

    try {
      res = await fetch('/api/users/user', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'GET',
      }).then((data) => data.json());

      if (res.user && res.user.token) {
        if (res.userInfo.preferredLanguage) {
          await i18n.changeLanguage(res.userInfo.preferredLanguage);
          setLang(res.userInfo.preferredLanguage);
        }
        saveUser(res);
      }
    } catch (e) {}
    setIsLoading(false);
  }, [i18n, saveUser]);

  useEffect(() => {
    refreshUser();
  }, []); // eslint-disable-line

  const changeTableRows = useCallback(
    async (key: string, value: number) => {
      if (userData) {
        await fetch('/api/users/user/updateTableSettings', {
          body: JSON.stringify({
            tableSettings: {
              key,
              value,
            },
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData.user.token}`,
          },
          method: 'PUT',
        });
        refreshUser();
      }
    },
    [refreshUser, userData]
  );

  return (
    <UserContext.Provider
      value={{
        isLoading,
        userData,
        setUser: saveUser,
        logout,
        changeLanguage,
        setLanguage,
        lang,
        defaultCurrency,
        changeTableRows,
      }}
    >
      {isLoading ? null : children}
    </UserContext.Provider>
  );
};
